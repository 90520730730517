import React from 'react';
import { graphql } from 'gatsby';
import CategoriesNav from '../components/CategoriesNav';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';

export default function HomePage({ data }) {
  const nav = data.nav.nodes;
  return (
    <Layout>
      <SubPageHeader title="Categories" />
      <CategoriesNav nav={nav} />
    </Layout>
  );
}

export const query = graphql`
  query {
    nav: allSanityNav {
      nodes {
        name
        id
        url {
          current
        }
        active
      }
    }
  }
`;
