import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GrFormNext } from 'react-icons/gr';

const CategoriesNavStyles = styled.nav`
  li {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0;
    }
  }
  a {
    position: relative;
    display: block;
    padding: 40px 30px 40px 40px;
    background: var(--grey);
    :hover {
      h3 {
        color: var(--green);
      }
      svg polyline {
        stroke: var(--green);
      }
    }
  }
  h3 {
    transition: color 0.2s ease-in-out;
  }
  .arrowRight {
    position: absolute;
    top: calc(50% - 30px);
    right: 20px;
    width: 60px;
    height: 60px;
    color: var(--black);
    svg polyline {
      transition: stroke 0.2s ease-in-out;
    }
  }
  @media (max-width: 767px) {
    a {
      padding: 20px 10px 20px 20px;
    }
    .arrowRight {
      top: calc(50% - 20px);
      width: 40px;
      height: 40px;
      right: 10px;
    }
  }
`;

export default function CategoriesNav({ nav }) {
  return (
    <CategoriesNavStyles>
      <nav>
        <ul>
          {nav.map((item) =>
            item.active ? (
              <li key={item.id}>
                <Link to={`/${item.url.current}`}>
                  <h3>{item.name}</h3>
                  <GrFormNext className="arrowRight" />
                </Link>
              </li>
            ) : (
              ''
            )
          )}
        </ul>
      </nav>
    </CategoriesNavStyles>
  );
}
